import React from "react"
import clsx from "clsx"
import { useMediaQuery } from "@material-ui/core"
import * as styles from "./icons.module.scss"

interface IPlus25Icon {
  isFocusing?: boolean
}

export default function Plus25Icon({ isFocusing = false }: IPlus25Icon) {
  const isMobile = useMediaQuery("(max-width:600px)")
  return (
    <div className={clsx(styles.block, { [styles.block__short]: isFocusing && isMobile })}>
      <p>
        +25% <span>к одобрению</span>
      </p>
    </div>
  )
}
