// extracted by mini-css-extract-plugin
export var anchor = "formPKW-module--anchor--uHYdG";
export var backgroundColor = "formPKW-module--backgroundColor--C5dlP";
export var backgroundSection = "formPKW-module--backgroundSection--C0lh3";
export var btsAuto = "formPKW-module--btsAuto--1AzNQ";
export var credit = "formPKW-module--credit--mgVEN";
export var descr = "formPKW-module--descr--Xablp";
export var descrLongTitle = "formPKW-module--descrLongTitle--moymc";
export var emptyForm = "formPKW-module--emptyForm--1o8s7";
export var form = "formPKW-module--form--k1O4v";
export var formContainer = "formPKW-module--formContainer--9yzKe";
export var formLongTitle = "formPKW-module--formLongTitle--jN3ii";
export var formWithTimer = "formPKW-module--formWithTimer--x5Mwu";
export var halvaDlyaGamerov = "formPKW-module--halvaDlyaGamerov--aNnFV";
export var head = "formPKW-module--head--p4tBq";
export var headLongTitle = "formPKW-module--headLongTitle--xSVq0";
export var img = "formPKW-module--img--lldKf";
export var info = "formPKW-module--info--+TlFC";
export var infoLongTitle = "formPKW-module--infoLongTitle--IZ3x6";
export var newYear = "formPKW-module--newYear--9rb10";
export var noGridOnMobForOffice = "formPKW-module--noGridOnMobForOffice--mbFNK";
export var section = "formPKW-module--section--eDWCL";
export var sectionLongTitle = "formPKW-module--sectionLongTitle--3UjC2";
export var sectionWithTimer = "formPKW-module--sectionWithTimer--jZGXu";
export var timer = "formPKW-module--timer--8i3in";