import React, { ChangeEvent, useEffect, useState } from "react"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@ecom/ui/components/AutocompleteField"
import CircularProgress from "@material-ui/core/CircularProgress"

import clsx from "clsx"
import { WrapperDataLayer } from "./WrapperDataLayer"

import { getDadata } from "../../../../helpers/formHelpers"

import * as styles from "./fields.module.scss"
import CheckedGreenIcon from "../icons/CheckedGreen"
import Plus25Icon from "../icons/Plus25"
import { TFioField } from "../../types"

export const FioField = ({
  type,
  setFieldValue,
  touched,
  errors,
  id,
  name,
  fioValue,
  handleBlur,
  isGreenApprove,
  inputsColor = "white",
  fioPlaceHolder = "ФИО",
  setFocusPhone,
}: TFioField) => {
  const [isFirstClick, setIsFirstClick] = useState(false)
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [flagSelectedValue, setFlagSelectedValue] = useState(false)
  const [isFocusing, setIsFocusing] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [isFocusingApprove, setIsFocusingApprove] = useState(false)
  const error = errors?.data?.surname || errors?.data?.name || errors?.data?.patronymic

  useEffect(() => {
    if (inputValue !== "") {
      setIsChanged(true)
    }

    let isCurrent = true

    const [inputSurname, inputName, ...inputPatronymicArr] = inputValue.split(" ")
    const inputPatronymic = inputPatronymicArr.join(" ")

    if ((inputSurname || inputName) && !!isCurrent) {
      setFieldValue("fio", {
        data: { surname: inputSurname, name: inputName, patronymic: inputPatronymic || null },
      })
    }
    return () => {
      isCurrent = false
    }
  }, [inputValue, setFieldValue])

  const onBlur = () => {
    const [inputSurname, inputName, ...inputPatronymicArr] = inputValue.split(" ")
    const inputPatronymic = inputPatronymicArr.join(" ")

    setIsLoading(true)
    setFieldValue("fio", {
      data: { surname: inputSurname, name: inputName, patronymic: inputPatronymic || null },
    })
    setIsFocusing(false)
    setIsLoading(false)
    setInputValue((prev) => prev.trimEnd())
  }

  const customHandleBlur = (e: any) => {
    setIsFocusingApprove(false)
    if (isChanged) {
      handleBlur(e)
    }
  }

  useEffect(() => {
    function fetchData() {
      setIsLoading(true)

      getDadata(type, inputValue).then((response) => {
        let suggestions = []
        if (response?.suggestions) {
          suggestions = response.suggestions
        }
        setOptions(suggestions)

        setIsLoading(false)
      })
    }

    if (inputValue) {
      if (!flagSelectedValue) fetchData()
    } else {
      setOptions([])
      setFieldValue("fio", { data: { surname: null, name: null, patronymic: null } })
    }
  }, [inputValue, setFieldValue, type, flagSelectedValue])

  useEffect(() => {
    if (flagSelectedValue && inputValue) {
      if (inputValue?.split(" ").length >= 3) {
        setOptions([])
        setFlagSelectedValue(false)
        setFocusPhone(true)
        return
      }
      setInputValue(`${inputValue} `)
      setFlagSelectedValue(false)
    }
  }, [flagSelectedValue, inputValue, setFocusPhone])

  const isCheckValidation =
    !error && isFirstClick ? <CheckedGreenIcon /> : <Plus25Icon isFocusing={isFocusingApprove} />

  const handleKey = (event: KeyboardEvent) => {
    const isFioDataComplete =
      fioValue?.data?.name && fioValue?.data.patronymic && fioValue?.data.surname && !errors

    if (event.key === "Enter") {
      event.preventDefault()
      if (isFioDataComplete) {
        setFocusPhone(true)
      }
    }
  }

  const handleOnInputChange = (_: ChangeEvent<HTMLInputElement>, newInputValue: string) => {
    const startWithSpace = newInputValue.startsWith(" ")
    const doubleSpace = newInputValue.includes("  ")
    const hasNumber = /[0-9]/.test(newInputValue)
    if (startWithSpace || doubleSpace || hasNumber) return
    setInputValue(newInputValue)
  }

  return (
    <WrapperDataLayer
      isChanged={isChanged}
      touched={Boolean(touched)}
      error={touched ? error || "" : ""}
      name={name}
    >
      <Autocomplete
        onKeyDown={handleKey}
        getOptionLabel={(option: Record<string, string>) => option.value || inputValue}
        filterOptions={(x: string[]) => (Array.isArray(x) ? x : [])}
        freeSolo
        autoComplete
        disableClearable
        autoHighlight
        options={Array.isArray(options) ? options : []}
        includeInputInList
        loading={isLoading}
        inputValue={inputValue.replace(/[\s-]{2,}/g, " ").trimStart()}
        onChange={() => {
          setFlagSelectedValue(true)
        }}
        onInputChange={handleOnInputChange}
        onBlur={onBlur}
        loadingText="Загрузка..."
        noOptionsText="Нет вариантов"
        renderInput={(params: Record<string, any>) => (
          <TextField
            {...params}
            onClick={() => setIsFirstClick(true)}
            onBlur={customHandleBlur}
            onFocus={() => setIsFocusingApprove(true)}
            inputRef={(input) => isFocusing && input && input.focus()}
            id={id}
            name={name}
            placeholder={fioPlaceHolder}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: styles.notchedOutline,
                root: clsx(styles.field, styles[inputsColor]),
              },
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {isGreenApprove && isCheckValidation}
                </>
              ),
            }}
            helperText={touched ? error || "" : ""}
            error={touched && !!errors}
          />
        )}
      />
    </WrapperDataLayer>
  )
}
