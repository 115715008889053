import React, { ChangeEvent } from "react"
import Link from "@material-ui/core/Link"
import Checkbox from "@material-ui/core/Checkbox"
import FormHelperText from "@material-ui/core/FormHelperText"
import { FormikTouched } from "formik"
import clsx from "clsx"
import { WrapperDataLayer } from "./WrapperDataLayer"

import { CheckIcon } from "../icons/CheckIcon"
import { EmptyIcon } from "../icons/EmptyIcon"

import * as styles from "./fields.module.scss"
import type { TFormVariant } from "../../types"
import { CheckIconPink } from "../icons/CheckedPink"
import { EmptyIconPink } from "../icons/EmptyIconPink"

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: boolean
  name: string
  id: string
  error?: string
  touched?: FormikTouched<{}>
  variant?: TFormVariant
}

export const AcceptmentField = ({
  value,
  variant = "standart",
  error,
  name,
  touched,
  ...props
}: Props) => {
  const isVariantHalvaDlyaGamerov = variant === "halvaDlyaGamerov"

  return (
    <WrapperDataLayer isChanged={value} touched={touched} error={error} name={name}>
      <div className={styles.acceptmentWrapper}>
        <div className={styles.acceptment}>
          <div>
            <Checkbox
              color="primary"
              checkedIcon={isVariantHalvaDlyaGamerov ? <CheckIconPink /> : <CheckIcon />}
              icon={isVariantHalvaDlyaGamerov ? <EmptyIconPink /> : <EmptyIcon />}
              checked={value}
              {...props}
            />
          </div>
          <div>
            <FormHelperText>
              <span className={clsx(styles.acceptmentContent, styles[variant])}>
                Выражаю{" "}
                <Link
                  className={clsx(styles.acceptmentLink, styles[variant])}
                  href="https://app.sovcombank.ru/policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always"
                >
                  согласие
                </Link>{" "}
                на&nbsp;обработку персональных данных и&nbsp;подтверждаю,&nbsp;что&nbsp;ознакомлен с
                <Link
                  className={clsx(styles.acceptmentLink, styles[variant])}
                  href="https://sovcombank.ru/about/pages/policy-personal-data"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always"
                >
                  {" "}
                  Политикой
                </Link>{" "}
                обработки персональных данных
              </span>
            </FormHelperText>
          </div>
        </div>
        <FormHelperText error classes={{ root: clsx(styles.acceptmentError, styles[variant]) }}>
          {error}
        </FormHelperText>
      </div>
    </WrapperDataLayer>
  )
}
