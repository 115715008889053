import React, { ChangeEvent, useEffect, useState } from "react"
import { FormikErrors, FormikTouched } from "formik"
import clsx from "clsx"

import TextField from "@material-ui/core/TextField"
import Autocomplete from "@ecom/ui/components/AutocompleteField"
import CircularProgress from "@material-ui/core/CircularProgress"

import { WrapperDataLayer } from "./WrapperDataLayer"

import * as styles from "./fields.module.scss"
import { getDadata } from "../../../../helpers/formHelpers"
import { FormValues, IDataCity } from "../../types"

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  fieldValue: {
    value: string
    unrestricted_value: string
    data: IDataCity | null
  }
  validateField: (name: string) => Promise<void> | Promise<string | undefined>
  touchField: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormValues>> | Promise<void>
  touched?: FormikTouched<{}>
  error: any
  id: string
  name: string
  inputsColor?: "white" | "grey"
}

const OPTIONS_CITY = { from_bound: { value: "city" }, to_bound: { value: "city" } }

export const CityField = ({
  setFieldValue,
  validateField,
  touchField,
  fieldValue,
  touched,
  error,
  id,
  name,
  inputsColor = "white",
}: Props) => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const errorText = error?.value || error?.data

  const [isFocusing, setIsFocusing] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  function fetchData() {
    setIsLoading(true)

    getDadata("address", fieldValue.value, OPTIONS_CITY).then((response) => {
      let suggestions = []
      if (response?.suggestions) {
        suggestions = response.suggestions
      }
      setOptions(suggestions)

      setIsLoading(false)
    })
  }
  useEffect(() => {
    if (fieldValue.value) {
      fetchData()
    } else {
      setOptions([])
      setFieldValue("localityAddress", { value: "", unrestricted_value: "", data: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue.value, setFieldValue])

  useEffect(() => {
    if (touched && !isFocusing) {
      validateField("localityAddress")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched, isFocusing])

  return (
    <WrapperDataLayer
      isChanged={isChanged}
      touched={touched}
      error={touched ? errorText || "" : ""}
      name={name}
    >
      <Autocomplete
        getOptionLabel={(option: Record<string, string>) => option?.value || option || ""}
        filterOptions={(x: string[]) => (Array.isArray(x) ? x : [])}
        freeSolo
        autoComplete
        blurOnSelect
        autoHighlight
        disableClearable
        options={Array.isArray(options) ? options : []}
        includeInputInList
        loading={isLoading}
        value={fieldValue.value}
        onFocus={() => {
          setIsFocusing(true)
          touchField("localityAddress", true)
        }}
        onInputChange={(_: ChangeEvent<HTMLInputElement>, newInputValue: string) => {
          setIsChanged(true)
          if (isFocusing) {
            setFieldValue("localityAddress", {
              value: newInputValue,
              unrestricted_value: newInputValue,
              data: null,
            })
          }
        }}
        onChange={(
          _: ChangeEvent<HTMLInputElement>,
          value: { value: string; undrestricted_value: string; data: IDataCity }
        ) => {
          setFieldValue("localityAddress", value)
        }}
        onBlur={() => setIsFocusing(false)}
        loadingText="Загрузка..."
        noOptionsText="Нет вариантов"
        renderInput={(params: Record<string, any>) => (
          <TextField
            {...params}
            id={id}
            name={name}
            placeholder="Город"
            InputProps={{
              ...params.InputProps,
              multiline: false,
              classes: {
                notchedOutline: styles.notchedOutline,
                root: clsx(styles.field, styles[inputsColor]),
              },
              endAdornment: <>{isLoading && <CircularProgress color="inherit" size={20} />}</>,
            }}
            helperText={touched && !isFocusing ? errorText : ""}
            error={touched && !isFocusing && !!error}
          />
        )}
      />
    </WrapperDataLayer>
  )
}
