// extracted by mini-css-extract-plugin
export var ballImg = "timer-module--ballImg--SHgFj";
export var cardImg = "timer-module--cardImg--I3qkw";
export var clockImg = "timer-module--clockImg--HRaSY";
export var closeBtn = "timer-module--closeBtn--dSbM-";
export var clsBtn = "timer-module--clsBtn--IErfP";
export var descr = "timer-module--descr---xSRc";
export var giftImg = "timer-module--giftImg--mEbPJ";
export var giftNYImg = "timer-module--giftNYImg--3VioI";
export var root = "timer-module--root--BZ4oj";
export var rootNY = "timer-module--rootNY--awHQc";
export var timer = "timer-module--timer--Qg7fR";
export var timerNY = "timer-module--timerNY--D4A+O";
export var wrapper = "timer-module--wrapper--FO6b6";
export var wrapperNY = "timer-module--wrapperNY--n+RUk";