import React from "react"
import Container from "@ecom/ui/components/Container"
import * as styles from "./progressBar.module.scss"
import { IProgressBarGreen } from "./helper"

export default function ProgressBarGreen({
  widthGreenBar,
  widthAnimationBar = 50,
  steps = 1,
}: IProgressBarGreen) {
  return (
    <Container className={styles.container}>
      <h4 className={styles.title}>
        <span className={styles.steps}> Шаг {steps} из 2.</span> Получите{" "}
        <span className={styles.green}>{steps === 1 ? "+50%" : "+40%"} к одобрению</span> за
        заполнение контактной информации
      </h4>
      <div className={styles.progressBar}>
        <div className={styles.animationBar} style={{ width: `${widthAnimationBar}%` }} />
        <div className={styles.greenBar} style={{ width: `${widthGreenBar}%` }} />
      </div>
    </Container>
  )
}
