import React from "react"

export const CloseIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3333 1.66651L1.66663 12.3332M12.3333 12.3332L1.66663 1.6665"
      stroke="#8E8E8E"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
