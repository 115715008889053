import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import { CloseIcon } from "../icons/CloseIcon"

import * as styles from "./sideBar.module.scss"

type Props = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  variantStyles?: "forForm"
}

export default function SideBar({ isOpen, onClose, children, variantStyles }: Props) {
  if (!isOpen) {
    return null
  }

  return (
    <div className={clsx(styles.root, variantStyles && styles[variantStyles])}>
      <Container className={styles.container}>
        <Button className={styles.btn} onClick={onClose}>
          <CloseIcon />
        </Button>
        {children}
      </Container>
    </div>
  )
}
