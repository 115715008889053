import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import PhoneWithSeven from "@ecom/ui/components/PhoneField/PhoneWithSeven"
import { WrapperDataLayer } from "./WrapperDataLayer"
import * as styles from "./fields.module.scss"
import CheckedGreenIcon from "../icons/CheckedGreen"
import Plus25Icon from "../icons/Plus25"
import { TPhoneField } from "../../types"

export const PhoneField = ({
  touched,
  error,
  isGreenApprove,
  handleBlur,
  name,
  inputsColor = "white",
  isFioFieldValidate,
  onPaste,
  focus,
  setFocus,
  ...restProps
}: TPhoneField) => {
  const isChanged = restProps.value.length > 1
  const [isFirstClick, setIsFirstClick] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  let greenIcon: JSX.Element | null = null

  if (!error && isFirstClick) {
    greenIcon = <CheckedGreenIcon />
  } else if (isFioFieldValidate || isFirstClick) {
    greenIcon = <Plus25Icon isFocusing={focus} />
  }

  const handleFocus = () => {
    setFocus(true)
    setIsFirstClick(true)
  }

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus])

  return (
    <WrapperDataLayer
      isChanged={isChanged}
      touched={touched}
      error={touched ? error || "" : ""}
      name={name}
    >
      <div onBlur={() => setFocus(false)} onFocus={handleFocus} className={styles.fieldWrapper}>
        <PhoneWithSeven
          {...restProps}
          inputRef={inputRef}
          InputProps={{
            classes: {
              notchedOutline: styles.notchedOutline,
              root: clsx(styles.field, styles[inputsColor]),
            },
            endAdornment: <>{isGreenApprove && greenIcon}</>,
            onPaste,
          }}
          onBlur={handleBlur}
          placeholder="Телефон"
          helperText={touched ? error : ""}
          error={touched && !!error}
          fullWidth
        />
      </div>
    </WrapperDataLayer>
  )
}
