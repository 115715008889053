import React, { ChangeEvent, FocusEventHandler, useEffect, useState } from "react"
import clsx from "clsx"

import DateField from "@ecom/ui/components/DateField"

import { FormikTouched } from "formik"
import { WrapperDataLayer } from "./WrapperDataLayer"

import * as styles from "./fields.module.scss"

type Props = {
  id: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  error?: string
  touched?: FormikTouched<{}>
  value?: string
  inputsColor?: "white" | "grey"
}

export const BirthField = ({
  touched,
  error,
  handleBlur,
  name,
  value,
  inputsColor = "white",
  ...restProps
}: Props) => {
  const [isChanged, setIsChanged] = useState(false)

  useEffect(() => {
    if (value !== "") {
      setIsChanged(true)
    }
  }, [value])

  const customHandleBlur = (e: any) => {
    if (isChanged) {
      handleBlur(e)
    }
  }

  return (
    <WrapperDataLayer
      isChanged={isChanged}
      touched={touched}
      error={touched ? error || "" : ""}
      name={name}
    >
      <div className={styles.fieldWrapper}>
        <DateField
          {...restProps}
          InputProps={{
            classes: {
              notchedOutline: styles.notchedOutline,
              root: clsx(styles.field, styles[inputsColor]),
            },
          }}
          value={value}
          onBlur={customHandleBlur}
          helperText={touched ? error : ""}
          error={touched && !!error}
          fullWidth
          max={new Date()}
          validAgeMin={18}
          validAgeMax={85}
          placeholder="Дата рождения"
        />
      </div>
    </WrapperDataLayer>
  )
}
