import React from "react"

export const EmptyIconPink = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 .8H6A5.2 5.2 0 0 0 .8 6v12A5.2 5.2 0 0 0 6 23.2h12a5.2 5.2 0 0 0 5.2-5.2V6A5.2 5.2 0 0 0 18 .8Z"
      stroke="#FF00FF"
      strokeWidth="1.6"
    />
  </svg>
)
