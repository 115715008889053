import sendRequest from "@ecom/ui/utils/sendRequest"

import type { OfficeData, OfficeDataWithMoreInfo } from "../types"

const API_URL = `${process.env.GATSBY_APP_HALVA_API_URL}/v1/cache/pickup-points/nearby`

export default function fetchOffices({
  kladrID,
  latitude,
  longitude,
  noFiltrationOffices,
}: {
  kladrID?: string
  longitude?: number
  latitude?: number
  noFiltrationOffices?: boolean
}): Promise<OfficeData[]> {
  const params: {
    countOffices: number
    clientType: string
    addressClassifier?: string
    longitude?: number
    latitude?: number
  } = {
    countOffices: 100,
    clientType: "INDIVIDUAL",
  }

  if (kladrID) {
    params.addressClassifier = kladrID
  } else if (latitude && longitude) {
    params.longitude = longitude
    params.latitude = latitude
  } else {
    return Promise.resolve([])
  }

  if (noFiltrationOffices) {
    return sendRequest(API_URL, params).then((points: OfficeDataWithMoreInfo[]) => points)
  }

  return sendRequest(API_URL, params).then((points: OfficeDataWithMoreInfo[]) =>
    points.filter(
      (item) => item.currencies?.personCash && !item.name.toLowerCase().includes("банкомат")
    )
  )
}
