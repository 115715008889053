import React from "react"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import * as styles from "./fields.module.scss"
import { ProductName } from "../../../../helpers/formHelpers"

type Props = {
  setProductName?: React.Dispatch<React.SetStateAction<ProductName>>
  productName: string
}

export function ChoiceProduct({ setProductName, productName }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      setProductName &&
      (event.target.value === "КК Халва" || event.target.value === "КК Халва Рефинансирование")
    ) {
      setProductName(event.target.value)
    }

    // кастомный DataLayer только для аб теста
    pushToDataLayer({
      event: "buttonClick",
      name: event.target.value,
      eventCategory: "click_switcher_content",
      eventAction: "",
      placement: "main",
      eventLabel: globalThis?.location?.href,
    })
  }

  return (
    <div className={styles.choiceProduct}>
      <h4 className={styles.choiceProductTitle}>Выберите продукт:</h4>
      <label htmlFor="productNameRadio-kk-halva">
        <input
          id="productNameRadio-kk-halva"
          className={styles.customRadio}
          type="radio"
          name="productNameRadio"
          value="КК Халва"
          onChange={handleChange}
          checked={productName === "КК Халва"}
        />
        <span className={styles.choiceProductText}>Карта Халва</span>
      </label>
      <label htmlFor="productNameRadio-refin">
        <input
          id="productNameRadio-refin"
          className={styles.customRadio}
          type="radio"
          name="productNameRadio"
          value="КК Халва Рефинансирование"
          onChange={handleChange}
          checked={productName === "КК Халва Рефинансирование"}
        />
        <span className={styles.choiceProductText}>Рефинансирование</span>
      </label>
    </div>
  )
}
