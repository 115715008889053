export const itemsSelect: Record<"value" | "label", string>[] = [
  {
    value: "Рассказали коллеги",
    label: "Рассказали коллеги",
  },
  {
    value: "От родственников / родителей",
    label: "От родственников / родителей",
  },
  {
    value: "От друзей / знакомых",
    label: "От друзей / знакомых",
  },
  {
    value: "Другое",
    label: "Другое",
  },
]
