import React, { useEffect, useState } from "react"

import { Placemark } from "react-yandex-maps"

import type { OfficeData } from "./types"

type Props = OfficeData & {
  onClick: (officeData: OfficeData) => void
  selectedOffice?: OfficeData | null
}

const defaultPlacemarkOptions = {
  preset: "islands#nightCircleDotIcon",
}

const selectedPlacemarkOptions = {
  preset: "islands#redCircleDotIcon",
}

export default function OfficePlacemark({
  id,
  latitude,
  longitude,
  name,
  address,
  mode,
  onClick,
  currencies,
  selectedOffice,
}: Props) {
  const geometry = [latitude, longitude]

  const [activeOffice, setActiveOffice] = useState(false)

  useEffect(() => {
    if (!selectedOffice || selectedOffice.address !== address) {
      setActiveOffice(false)
      return
    }
    setActiveOffice(true)
  }, [address, selectedOffice])

  const properties = {
    hintContent: name,
    balloonContentHeader: address,
    balloonContentBody: mode ? mode.forIndividuals.join("<br/>") : "",
  }

  function handleClick() {
    onClick({
      id,
      latitude,
      longitude,
      name,
      address,
      mode,
      currencies,
    })
  }

  return (
    <Placemark
      onClick={handleClick}
      geometry={geometry}
      properties={properties}
      options={activeOffice ? selectedPlacemarkOptions : defaultPlacemarkOptions}
    />
  )
}
