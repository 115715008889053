import React, { ReactNode } from "react"

import { CountDown } from "../Headers/NewHeader/RightSection/CountDown"
import closeBtn from "./img/arrowClose.svg"
import blackArrow from "./img/arrowBlack.svg"
import { ClockImage } from "./img/ClockImage"
import { GiftImage } from "./img/GiftImage"
import { Ball } from "./img/Ball"
import { NewYearGift } from "./img/NewYearGift"
import type { TImgTimerType } from "../FormPKW/types"
import * as styles from "./timer.module.scss"
import { HalvaCard } from "./img/HalvaCard"

const defaultDescription = (
  <>
    Успейте получить <span>подарок</span>: <br />
    подписку «Халва.Десятка» на месяц <br />
    <span>бесплатно!</span>
  </>
)

type TimerProps = {
  isNewYear?: boolean
  countDownTime?: number
  imgTimerType?: TImgTimerType
  timerDescription?: ReactNode
}

const Timer = ({
  countDownTime,
  imgTimerType,
  timerDescription = defaultDescription,
  isNewYear = false,
}: TimerProps) => (
  <>
    {!isNewYear ? (
      <div className={styles.root}>
        <div className={styles.clsBtn}>
          <img src={closeBtn} className={styles.closeBtn} alt="closeBtn" />
        </div>
        <div className={styles.wrapper}>
          {imgTimerType === "halva_card" ? (
            <HalvaCard alt="halva card" className={styles.cardImg} />
          ) : (
            <ClockImage alt="clock" className={styles.clockImg} />
          )}

          <div className={styles.timer}>
            <CountDown countDownTime={countDownTime} />
          </div>
          <p className={styles.descr}>{timerDescription}</p>
        </div>
        <GiftImage alt="gift" className={styles.giftImg} />
      </div>
    ) : (
      <div className={styles.rootNY}>
        <div className={styles.clsBtn}>
          <img src={blackArrow} className={styles.closeBtn} alt="closeBtn" />
        </div>
        <div className={styles.wrapper}>
          <Ball alt="ball" className={styles.ballImg} />

          <div className={styles.timerNY}>
            <CountDown countDownTime={countDownTime} />
          </div>
          <p className={styles.descr}>
            Играйте в игру с 04.12 до 14.01,
            <br />
            получайте подарки и участвуйте в<br />
            розыгрыше главного <br />
            приза – автомобиля
          </p>
        </div>
        {imgTimerType === "halva_card" ? (
          <HalvaCard alt="nygift" className={styles.giftNYImg} />
        ) : (
          <NewYearGift alt="nygift" className={styles.giftNYImg} />
        )}
      </div>
    )}
  </>
)

export default Timer
