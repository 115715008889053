import React from "react"

import { YMaps, Map as YMap } from "react-yandex-maps"
import type { MapState } from "react-yandex-maps"

import Placemark from "./Placemark"

import type { OfficeData } from "./types"

type Props = {
  className: string
  state: MapState
  offices: OfficeData[]
  onSelect: (v: OfficeData) => void
  selectedOffice?: OfficeData
}

export default function Map({ className, state, offices, onSelect, selectedOffice }: Props) {
  return (
    <YMaps>
      <YMap className={className} state={state}>
        {offices.map((officeProps) => (
          <Placemark
            selectedOffice={selectedOffice}
            {...officeProps}
            key={officeProps.id}
            onClick={onSelect}
          />
        ))}
      </YMap>
    </YMaps>
  )
}
