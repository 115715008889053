import React from "react"
import clsx from "clsx"

import DadataField from "@ecom/ui/components/DadataField"

import type { MapState } from "react-yandex-maps"
import type { OfficeData } from "../types"
import type { DadataAddressDadataValue } from "../../../interfaces/dadata"

import { SearchIcon } from "../icons/Search"

import * as styles from "./search.module.scss"

const LOCALITY_DADATA_OPTIONS = {
  count: 10,
  to_bound: { value: "house" },
}

type Props = {
  kladrID?: string
  offices: OfficeData[]
  setMapState: React.Dispatch<React.SetStateAction<MapState>>
  smallHeightInput?: boolean
}

export function Search({ setMapState, kladrID, offices, smallHeightInput }: Props) {
  const dadataOptions = {
    ...LOCALITY_DADATA_OPTIONS,
    locations: [{ kladr_id: kladrID }],
  }
  const inputProps = {
    classes: {
      root: clsx(styles.input, smallHeightInput && styles.smallHeightInput),
      notchedOutline: styles.outline,
    },
    endAdornment: <SearchIcon className={styles.searchIcon} />,
  }

  function handleSelect(addressData: DadataAddressDadataValue) {
    if (!addressData) {
      return
    }

    const { geo_lat, geo_lon } = addressData.data

    setMapState({
      center: [parseFloat(geo_lat), parseFloat(geo_lon)],
      zoom: 12,
    })

    let dmin = 100
    let nearestOffice: OfficeData

    offices.forEach((item) => {
      const a = parseFloat(geo_lat) - item.latitude
      const b = parseFloat(geo_lon) - item.longitude
      const distance = Math.sqrt(a * a + b * b)

      if (distance < dmin) {
        dmin = distance
        nearestOffice = item
      }
    })

    // @ts-ignore
    if (nearestOffice) {
      setMapState({
        center: [nearestOffice.latitude, nearestOffice.longitude],
        zoom: 17,
      })
    }
  }

  const handleKeyDownCancelSubmit = (event: { key: string; preventDefault: () => void }) => {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }

  return (
    <div className={clsx(styles.inputOuter, smallHeightInput && styles.label)}>
      <DadataField
        onKeyDown={handleKeyDownCancelSubmit}
        type="address"
        label="Начните вводить адрес"
        fullWidth
        multiline={false}
        onChange={handleSelect}
        InputProps={inputProps}
        dadataOptions={dadataOptions}
      />
    </div>
  )
}
