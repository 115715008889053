import React, { useState, useEffect } from "react"
import clsx from "clsx"
import type { MapState } from "react-yandex-maps"
import { Search } from "./Search"
import Map from "./Map"
import SideBar from "./SideBar"
import { OfficeInfoWithoutRates } from "./OfficeInfoWithoutRates"

import type { OfficeData, OfficeDataWithMoreInfo } from "./types"

import * as styles from "./pickup.module.scss"

type Props = {
  officesFromRequest: OfficeDataWithMoreInfo[]
  kladr?: string
  className?: string
  defLat: string
  defLon: string
  initialScale?: number
  officeFullData: OfficeDataWithMoreInfo | null
  changeOfficeAtSelect: (arg0: string) => void
}

export function PickupForForm({
  officesFromRequest,
  kladr = "7700000000000",
  className = "",
  defLat,
  defLon,
  initialScale = 14,
  officeFullData,
  changeOfficeAtSelect,
}: Props) {
  const [kladrID, setKladrID] = useState(kladr)
  const [mapState, setMapState] = useState<MapState>({} as MapState)

  const [selectedOffice, setSelectedOffice] = useState<OfficeData>()

  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setKladrID(kladr)
    if (defLat && defLon)
      setMapState({
        center: [parseFloat(defLat), parseFloat(defLon)],
        zoom: initialScale,
      })
  }, [defLat, defLon, kladr, setKladrID, initialScale])

  function handleSelect(office: OfficeData, withZoom = false) {
    setIsSelected(false)
    setSelectedOffice(office)
    setIsSelected(true)

    if (office?.address && changeOfficeAtSelect) {
      changeOfficeAtSelect(office.address)
    }

    if (office?.latitude && office.longitude && withZoom) {
      setMapState({
        center: [office.latitude, office.longitude],
        zoom: 16,
      })
    }
  }

  useEffect(() => {
    if (officeFullData && officeFullData?.id !== selectedOffice?.id) {
      handleSelect(officeFullData, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeFullData])

  function handleClose() {
    setIsSelected(false)
  }
  return (
    <>
      <div className={clsx(styles.mapOuter, styles.heightForForm, className)}>
        {!isSelected && (
          <Search
            offices={officesFromRequest}
            setMapState={setMapState}
            kladrID={kladrID}
            smallHeightInput
          />
        )}
        <Map
          className={styles.mapInner}
          state={mapState}
          offices={officesFromRequest}
          onSelect={handleSelect}
          selectedOffice={selectedOffice}
        />
      </div>
      <SideBar variantStyles="forForm" isOpen={isSelected} onClose={handleClose}>
        <OfficeInfoWithoutRates info={selectedOffice as OfficeData} />
      </SideBar>
    </>
  )
}
