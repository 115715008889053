import React from "react"
import clsx from "clsx"

import type { OfficeData } from "../types"
import * as styles from "./officeInfoWithoutRates.module.scss"

type Props = {
  info: OfficeData
  variantStyles?: "withoutRates"
}

export function OfficeInfoWithoutRates({ info, variantStyles = "withoutRates" }: Props) {
  const { name, address, mode } = info || {}
  return (
    <div className={variantStyles && styles[variantStyles]}>
      <h1 className={styles.head}>{name}</h1>
      <h3 className={styles.addressHead}>Адрес отделения:</h3>
      <p className={styles.addressSubtitle}>{address}</p>
      {mode && (
        <>
          <h3 className={clsx(styles.addressHead, styles.workSchedule)}>График работы:</h3>
          <h4 className={styles.addressSubtitle}>
            {mode.forIndividuals.map((item: string, index: number) => (
              <div key={index}>{item}</div>
            ))}
          </h4>
        </>
      )}
    </div>
  )
}
